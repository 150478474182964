import React from 'react';
import { ErroredModule } from '../../../components/page-builder/errored-module';

export interface ErrorModuleProps {
  typename: string;
  filePath: string;
}

export const ErrorModule = ({ typename, filePath }: ErrorModuleProps) => {
  return (
    <ErroredModule>
      <div>
        Missing component for type {typename}. Check the following things:
        <ul>
          <li>
            Make sure you have created a file for {typename} in
            `src/redesign/page-builder/${filePath}`
          </li>
          <li>
            Make sure you have declared a default export for {typename} in the
            component file: src/redesign/page-builder/{filePath}
          </li>
        </ul>
      </div>
    </ErroredModule>
  );
};
