import { SanityModuleSettings } from '../../graphql-types';
import { ModuleTheme } from '../redesign/atoms/module/module';
import { Spacing } from '../redesign/types';

export const useSanityModuleSettings = () => {
  const getModuleSettings = (settings: SanityModuleSettings = {}) => {
    const theme = (settings.theme as ModuleTheme) || 'transparent';
    const elementId = settings.elementId || '';
    const margins = {
      marginTop: [
        settings.moduleSpacing?.marginTopMobile || 'none',
        settings.moduleSpacing?.marginTopDesktop || 'none'
      ] as Spacing,
      marginBottom: [
        settings.moduleSpacing?.marginBottomMobile || 'none',
        settings.moduleSpacing?.marginBottomDesktop || 'none'
      ] as Spacing
    };

    return {
      theme,
      elementId,
      margins
    };
  };
  return { getModuleSettings };
};
