import React, { lazy, memo, Suspense, useMemo } from 'react';

import { ComponentNameToValidPathStr } from '../../../lib/dev-template-utils';
import ImportComponentErrorBoundary from './import-component-error-boundary';
import { ErrorModule } from './error-module';

import { PageBuilderImportComponentProps } from '../../types';

export interface ImportComponentProps {
  __typename: string;
  componentProps: PageBuilderImportComponentProps;
}

export default memo(
  function ImportComponent({
    __typename,
    componentProps
  }: ImportComponentProps) {
    const isSSR = typeof window === 'undefined';
    const path = useMemo(() => ComponentNameToValidPathStr(__typename), [
      __typename
    ]);

    const Component = useMemo(
      () =>
        lazy(() =>
          import(`../../page-builder/${path}`).catch(() => ({
            default: () => <ErrorModule typename={__typename} filePath={path} />
          }))
        ),
      [__typename, path]
    );

    return (
      <ImportComponentErrorBoundary typename={__typename} filePath={path}>
        {!isSSR && (
          <Suspense fallback={<div />}>
            <Component {...componentProps} />
          </Suspense>
        )}
      </ImportComponentErrorBoundary>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.__typename === nextProps.__typename;
  }
);
